import React, { useEffect, useState } from 'react';
 import './App.css';
// import './background.css';

import { TW } from './components/Typewriter';
import { Carousel } from './components/Carousel';
import { OurWork } from './pages/OurWork';
import {
  BrowserRouter, Route, Routes
} from "react-router-dom";

import { Link, animateScroll as scroll } from "react-scroll";
import { PrivacyPolicy } from './pages/PrivacyPolicy';
const HeaderLink = ({ to, text, main = false, offset, invert }: { main?: boolean, invert?: boolean, to: string, text: string, offset: number }) => (
  <li>
    <Link
      style={invert ? {color: 'white'} : {}}
      activeClass="active"
      to={to}
      spy={true}
      smooth={true}
      duration={500}
      offset={offset}
    >
      {text}
    </Link>
  </li>
)

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) { // Adjust the value as needed
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={scrolled ? 'header header--scrolled' : 'header'}>
      <nav>
        <ul>
          <HeaderLink invert={scrolled} to="appIdea" text="Home" offset={20} />
          {/* <HeaderLink to="about" text='About' /> */}
          <HeaderLink invert={scrolled} to="ourWork" text="Apps" offset={-80} />
          <HeaderLink invert={scrolled} main={true} to="contact" text="Get In Touch" offset={-80} />
        </ul>
      </nav>
    </header>
  );
};


const Backdrop = () => {
    // You can add state here if you want to handle the switch functionality
    const [isBgSwitched, setIsBgSwitched] = useState(false);

    const handleSwitchChange = () => {
      setIsBgSwitched(!isBgSwitched);
      // Here you can add logic to change the background or other functionality
    };

    return (
      <div className='wrapper'>
        <div className='hero'>

        </div>

      </div>
    );
  }




function Home() {
  return (
    <div className="App">
      
      <Header/>

      <section className="Welcome" id="appIdea">
        <h1 style={{textAlign: 'center'}}>Got an App Idea?</h1>

        {/* <h4 className='scroll_down'>scroll down...</h4> */}
    
        <p>Build Your App Today</p>

        
      </section>

      <section  id="ourWork">

        <OurWork/>
       
      </section>

      {/* <section id="about">
        <h2>About Us</h2>
        <p>

        We specialize in creating top-notch iOS and Android apps. Ready to turn your vision into reality? We're here for you!
        </p>

       
      </section> */}
     

      <section className="Welcome" id="contact">
        <h2>Get in touch today!</h2>
        <p>

          We specialize in creating top-notch iOS and Android apps. Ready to turn your vision into reality? We're here for you!
        </p>
        <button onClick={() => window.location.href = "mailto:info@appidea.au"} className='myButton'>Get In Touch</button>

      </section>

      <footer>
        &copy; 2023 APP IDEA PTY LTD.
        <br></br>ABN 84 670 786 339
      </footer>

    </div>
  );
}

const App = () => {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/*' element={<Home />} />
          <Route path="/privacy-policy/:app_name/:date" element={<PrivacyPolicy />} />

        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App;
