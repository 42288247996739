



type AppListing = {
  image: any;
  title: string;
  description: string;
  description_2?: string;

  google_url: string;
  apple_url:string;
}
const apps: AppListing[] = [
  {
    image: require('../assets/parentkit.png'),
    title: "Parent Kit",
    description: `The Parent Kit App is a resource guide to support parents on their journey of transitioning to and from parental leave.`,
    google_url: "https://play.google.com/store/apps/details?id=au.com.parentkit",
    apple_url: "https://apps.apple.com/au/app/parent-kit/id1615105624"
  },
  {
    image: require('../assets/clean-link.png'),
    title: "Clean Link",
    description: `In today's digital age, sharing a link often means unintentionally sharing tracking data. This allows websites to monitor who's sharing what and with whom.`,
    description_2: `Enter Clean Link: your solution to stripping away those intrusive URL trackers and reclaiming some of your online privacy.`,
    google_url: "https://play.google.com/store/apps/details?id=com.clean_link",
    apple_url: "https://apps.apple.com/au/app/clean-link/id6470957337"
  },
  {
  image: require('../assets/chill-cat.jpg'),
  title: "Chill Cat",
  description: 'A mouse simulator so you and your cat can chill out',
  google_url: "https://play.google.com/store/apps/details?id=au.appidea.catgame",
  apple_url: "https://apps.apple.com/au/app/chill-cat/id6451443193"
}
]

const play_badge = require("../assets/google-play-badge.png")
const app_store_badge = require("../assets/app-store-badge.png")
const Badge = ({ google, url }: { google: boolean, url: string }) => (
  <a href={url} style={{ display: 'inline-block', margin: '0 10px', textAlign: 'center', lineHeight: '0' }}>
    <img style={{ width: google ? '145px' : '120px', verticalAlign: 'top', marginTop: google ? '-6px' : '0' }} src={google ? play_badge : app_store_badge} alt={google ? 'Google Play' : 'App Store'} />
  </a>
);






export const OurWork = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      {apps.map((a,index) => (
        <div key={a.title} style={{ textAlign: 'center', marginBottom: '20px' }}>
          {index === 0 && <hr style={{ width: '80%', margin: '10px auto', border: '1px solid #ccc' }} />}

          <h3>{a.title}</h3>
          <img style={{ width: '50%', maxWidth: '150px', borderRadius: '15px', marginBottom: '10px', marginTop: '10px' }} src={a.image} alt={a.title} />
          <p>{a.description}</p>
          {a.description_2 && <p>{a.description_2}</p>}

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Badge google={true} url={a.google_url} />
            <Badge google={false} url={a.apple_url} />
          </div>
          {index < apps.length - 1 && <hr style={{ width: '80%', margin: '10px auto', border: '1px solid #ccc' }} />}

        </div>
      ))}
    </div>
  );
};

